import {
  getBespokeMenu,
  getContactUsMenu,
  getDiamondsMenu,
  getEducationMenu,
  getEngagementRingsMenu,
  getEternityRingsMenu,
  getGemstonesMenu,
  getJewelleryMenu,
  getWeddingRingsMenu,
} from './constants'

import type { Category, SubCategory, SubMenuItem, NavigationMenu } from './types'

// TODO: drop processSubCategories and related if there will be an API that will handle it
// Consolidated processing function for both engagement and eternity rings
// copied logic from Nav.ascx.cs
function processSubCategories(subCategories: SubCategory[]): any[] {
  return subCategories.map((subCategory) => ({
    title: subCategory.name,
    icon: formatIconUrl(subCategory.iconUrl),
    link: generateFriendlyUrl(subCategory),
  }))
}

function formatIconUrl(iconUrl: string): string {
  return `icon77 icon77-${iconUrl.replace('.svg', '').replace('/images/NewImages/HeaderIcons/', '').replace('?v=1', '')}`
}

function generateFriendlyUrl(subCategory: SubCategory): string {
  return `/shop.aspx?CatId=${subCategory.categoryId}&SubCatId=${subCategory.id}`
}

// Process categories function
export function processCategories(categories: Category[]) {
  const processedData: { [key: string]: any[] } = {
    engRingsStyles: [],
    eternityStyles: [],
  }

  const categoryProcessors: { [key: number]: (category: Category) => void } = {
    1: (category) => processedData.engRingsStyles.push(...processSubCategories(category.subCategories)),
    3: (category) => processedData.eternityStyles.push(...processSubCategories(category.subCategories)),
  }

  categories.forEach((category) => {
    const processor = categoryProcessors[category.id]
    if (processor) {
      processor(category)
    }
  })

  return processedData
}

// Helper function to extend subItems based on a condition
function extendSubItemsWithStyles(subItems: SubMenuItem[], styles: SubMenuItem[], targetTitle: string): SubMenuItem[] {
  return subItems.map((item) => (item.title === targetTitle ? { ...item, subItems: styles } : item))
}

export function getNavigationMenu({
  i18nTranslation,
  localePath,
  translateShopRoute,
}: {
  engRingsStyles?: SubMenuItem[]
  eternityStyles?: SubMenuItem[]
  i18nTranslation: any
  localePath: any
  translateShopRoute: any
}): NavigationMenu {
  const engagementRings = getEngagementRingsMenu(i18nTranslation, localePath, translateShopRoute)
  const weddingRingsMenu = getWeddingRingsMenu(i18nTranslation, localePath, translateShopRoute)
  const eternityRingsMenu = getEternityRingsMenu(i18nTranslation, localePath, translateShopRoute)
  const jewelleryMenu = getJewelleryMenu(i18nTranslation, localePath, translateShopRoute)
  const diamondsMenu = getDiamondsMenu(i18nTranslation, localePath, translateShopRoute)
  const gemstonesMenu = getGemstonesMenu(i18nTranslation, localePath, translateShopRoute)
  const bespokeMenu = getBespokeMenu(i18nTranslation, localePath)
  const educationMenu = getEducationMenu(i18nTranslation, localePath)
  const contactUsMenu = getContactUsMenu(i18nTranslation, localePath)

  // Construct the final navigation menu array with the extended subItems
  return [
    engagementRings,
    weddingRingsMenu,
    eternityRingsMenu,
    jewelleryMenu,
    diamondsMenu,
    gemstonesMenu,
    bespokeMenu,
    educationMenu,
    contactUsMenu,
  ]
}
