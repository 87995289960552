<template>
  <Transition name="fade">
    <div id="divHeaderItem2" class="divHeaderItem2Dropdown dropdown dropdown-scroll">
      <div id="divLanguageContent" class="language">
        <div id="divLanguageOptionsContent" class="divLanguageOptionsContent language_options clearfix">
          <div class="scroll-row">
            <span class="language_title">{{ $t('location', 'Location') }}:<br /></span>
            <select class="headerCountries headerCountriesDropdown dropdown-menu" @change="changeHeaderCountry">
              <option
                v-for="country in countries"
                :key="country.Id"
                :value="country.Id"
                :selected="country.Id === currentCountry?.Id"
              >
                {{ country.Name }}
              </option>
            </select>
          </div>
          <div class="scroll-row">
            <span class="language_title">{{ $t('currency', 'Currency') }}:<br /></span>
            <select class="headerCurrencies headerCurrenciesDropdown dropdown-menu" @change="changeHeaderCurrency">
              <option
                v-for="currency in currencies"
                :key="currency.Id"
                :value="currency.Id"
                :selected="currency.Id === currentCurrency?.Id"
              >
                {{ currency.Name }}
              </option>
            </select>
          </div>
          <div class="scroll-row dropdown-ignore">
            <span class="language_title">{{ $t('language', 'Language') }}:<br /></span>
            <select class="headerLanguages headerLanguagesDropdown dropdown-menu" @change="changeLanguage">
              <option
                v-for="{ Language } in languages.LanguageSelections"
                :key="Language.Id"
                :selected="Language.Id === currentLanguage?.Id"
                :value="Language.Id"
              >
                {{ Language.MenuName }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script setup lang="ts">
import { useNewSettingsStore } from '~/store/newSettingsStore'

const { currentCountry, currentLanguage, currentCurrency, languages } = storeToRefs(useNewSettingsStore())
const { countries, currencies, changeCountryById, changeHeaderCurrencyById, changeLanguageById } =
  useCurrencySelection()

const changeHeaderCountry = (event: Event & { target: HTMLSelectElement }) => changeCountryById(event.target.value)
const changeHeaderCurrency = (event: Event & { target: HTMLSelectElement }) =>
  changeHeaderCurrencyById(event.target.value)
const changeLanguage = (event: Event & { target: HTMLSelectElement }) => changeLanguageById(event.target.value)
</script>

<style scoped lang="postcss">
#divShowHideLanguage {
  .dropdown.dropdown-scroll {
    .scroll-row {
      margin-bottom: 12px;

      span {
        margin-top: 0;
        margin-bottom: 6px;
      }
    }

    .scroll-row select {
      height: 24px;
      /*font-family: "Nunito Sans Bold";*/
      font-size: 13px !important;
      letter-spacing: 1px;
      text-transform: uppercase;
      border: none;
      white-space: normal;
      height: auto;
      padding-right: 16px;
      appearance: none;

      &::-ms-expand {
        display: none;
      }

      &:focus {
        border: none;
      }

      option {
        padding-left: 5px;
      }
    }
  }

  @media (max-width: 1023px) {
    .dropdown {
      margin-left: 0 !important;
      width: 100%;
      box-sizing: content-box;
      display: block !important;
      max-height: 1000px; /* adjusted to always be present */
      overflow: hidden;
      padding: 0;
      box-shadow: none;
      background-color: #fff;
      position: absolute;
      left: -1px;
      z-index: 1;
      min-width: 247px; /* adjusted to always be present */
      height: auto; /* adjusted to always be present */
      border: 1px solid black; /* adjusted to always be present */
    }

    .language_title {
      /*font-family: "Nunito Sans";*/
      font-size: 13px;
      letter-spacing: 1px;
    }

    #divLanguageContent {
      padding: 8px 16px;
    }
  }

  @media (min-width: 1024px) {
    .icon77-ic_down {
      transform: scale(0.77);
    }

    .dropdown.dropdown-scroll .scroll-row {
      margin-bottom: 18px;
      position: relative;

      &:last-of-type {
        margin-bottom: 0px;
      }

      .icon77-ic_down {
        position: absolute;
        right: 10px;
        bottom: 0;
        pointer-events: none;
        transform: scale(0.5);
      }

      select {
        text-indent: 0;
        /*padding-left: 14px;*/
        position: relative;
        background: none;
      }

      .language_title {
        text-transform: none;
        /*font-family: "Nunito Sans";*/
        font-size: 13px;
        letter-spacing: 1px;
      }
    }

    .divHeaderItem2Dropdown {
      max-width: 220px;
      padding: 16px;
      @apply bg-grey-100;
    }
  }
}
</style>
