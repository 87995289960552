<template>
  <form
    data-di-form-track
    data-di-form-id=""
    novalidate
    class="lg:mr-10"
    @submit.prevent="submit">

    <button type="button" class="mb-3" @click="$emit('prevStep')">
        <i class="icon77 icon77-chevron -left"></i>
        <span class="_display-5">
            {{$t('book-an-appointment', 'Book an Appointment')}}
        </span>
    </button>

    <div class="apptFormTitle _my-2">
        <div class="_float-right">
            <span class="_p -small">* {{$t('required-field', 'Required field')}}</span>
        </div>
        <h3 class="_text-uppercase">
            {{$t('appoint-form-contact-title', 'Your Contact Details:')}}
        </h3>
    </div>

    <ui-form-group
          v-model="$v.user.FirstName"
          :placeholder="$t('first-name', 'First Name')"
          data-cy="first-name"
        />

    <ui-form-group
      v-model="$v.user.LastName"
      :placeholder="$t('last-name')"
      data-cy="last-name"
    />

    <ui-form-group
      v-model="$v.user.Email"
      :placeholder="$t('email-address')"
      data-cy="email-address"
    />

    <div class="_form-row">
      <div class="_col-lg-6">
        <ui-form-group
          v-model="$v.user.PhoneCode"
          type="select"
          :placeholder="$t('phone-country', 'Phone Country')"
          data-cy="phone-country"
        >
          <template v-for="pc in phoneCodes">
            <option v-if="pc.Number" :value="pc.Number">
              {{ pc.Name }} (+{{ pc.Number }})
            </option>
            <option v-else disabled>{{ pc.Name }}</option>
          </template>
        </ui-form-group>
      </div>
      <div class="_col">
        <ui-form-group
          v-model="$v.user.PhoneNumber"
          :placeholder="$t('phone-number')"
          data-cy="Phone Number"
        />
      </div>
    </div>

    <div id="gdprCheckboxHolderFooter"  class="gdprCheckboxHolder fieldHolder _mb-3">
        <input type="checkbox" v-model="gdprCheckboxFooter" id="gdprCheckboxFooter" data-ga4-label="Consent Checkbox" data-ga4-pii class="required hidden" :data-empty-error-message="$t('gdpr-checkbox-notselected-error', 'In order to proceed you need to agree with the new privacy policy.')" />
        <label for="gdprCheckboxFooter" class="_p">
            <span v-html="$t('apt-forms-tos-', {
                link_privacy_policy: translateURL('/Footer/Privacy.aspx'),
                link_terms: translateURL('/NewApp/Default.aspx?app=terms'),
              }, 'By submitting your details, you consent to receive emails from 77 Diamonds. You are free to unsubscribe at any time. For further information, please read our <a href={link_privacy_policy}>Privacy Policy</a> and our <a href={link_terms}>Terms and Conditions</a>')
              .replace('Terms and Conditions', 'Terms & Conditions')">
            </span>
        </label>
    </div>

    <button type="submit" :disabled="!gdprCheckboxFooter" class="_cta-white _btn-block _mb-1 continue" data-cy="continue-button">
        {{$t('appoint-form-next-btn', 'Next')}}
    </button>
  </form>
</template>

<style lang="scss"></style>

<script setup>
import { useVuelidate } from '@vuelidate/core'
import {
  required,
  minLength,
  between,
  email,
  requiredIf,
  helpers,
} from "vuelidate/lib/validators";
import mockedPhoneCodes from '@/mockedData/phoneCodes'

import { useOnlineAppointments } from '../store'



const emit = defineEmits(['nextStep'])

const phone = helpers.regex("phone", /^$|^\d+$|^\d+$/);

const phoneCodes = computed(() => mockedPhoneCodes)

const user = ref({
    FirstName: null,
    LastName: null,
    Email: null,
    PhoneCode: null,
    PhoneNumber: null,
});

const gdprCheckboxFooter = ref(false)

// Vuelidate validations
const rules = {
  user: {
    FirstName: { required },
    LastName: { required, minLength: minLength(3) },
    Email: { required, email },
    PhoneCode: { required },
    PhoneNumber: { required, phone },
  }
}

const $v = useVuelidate(rules, { user })

const submit = () => {
  $v.value.user.$touch()

  if ($v.value.user.$invalid) {
    console.log("error");
    setTimeout(() => document.querySelector('._form-group--error :first-child ._form-control').focus(), 10)
  } else {
    // do your submit logic here
    console.log("ok");

    // this.setStep(step);
    emit('nextStep', { userData: user.value })
  }
}

  // export default {
  //   data() {
  //     return {
  //         gdprCheckboxFooter: false,
  //     };
  //   },

  //   validations() {
  //     // let isDeliveryAddress = requiredIf(() => this.user.DeliveryOption == 'DeliveryAddress')

  //     return {
  //       user: {
  //         FirstName: { required },
  //         LastName: { required, minLength: minLength(3) },
  //         Email: { required, email },
  //         PhoneCode: { required },
  //         PhoneNumber: { required, phone },
  //       },
  //     };
  //   },

  //   computed: {``
  //     ...mapState([
  //       "user"
  //     ]),

  //     phoneCodes() {
  //       return _77Settings.PhoneCodes;
  //     },
  //   },
  //   methods: {

  //     submit() {
  //       this.$v.user.$touch();

  //       if (this.$v.user.$invalid) {
  //         console.log("error");
  //         setTimeout(
  //           () => $("._form-group--error:first ._form-control").focus(),
  //           10
  //         );
  //       } else {
  //         // do your submit logic here
  //         console.log("ok");

  //         // this.setStep(step);
  //         this.$emit('nextStep', { userData: this.user })
  //       }
  //     },
  //   },

  //   mounted() {
  //   },
  // };
</script>