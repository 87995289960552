import { useNewSettingsStore } from '~/store/newSettingsStore'
import { useRouteStore } from '~/store/routesStore'

import type { CountryItem, Currency, Language, PhoneNumberInfo } from '~/types/currencySelection'

export function useCurrencySelection() {
  const { countries, currencies, phoneNumbers } = storeToRefs(useNewSettingsStore())
  // Use useAsyncData for fetching countries, currencies, and languages
  const routeStore = useRouteStore()
  const router = useRouter()
  const route = useRoute()
  const switchLocalePath = useSwitchLocalePath()

  const store = useNewSettingsStore()
  const { languages } = storeToRefs(useNewSettingsStore())

  const getLanguageById = (id: number) => {
    return languages.value.LanguageSelections?.find((language) => Number(language.Language.Id) === id)?.Language
  }

  const getCurrencyById = (id: number) => {
    return currencies.value?.find((c) => Number(c.Id) === id)
  }

  // Function to handle country change
  const changeCountryById = (id: number | string) => {
    const country = countries.value?.find((c) => Number(c.Id) === Number(id)) as CountryItem
    const phoneNumber = phoneNumbers.value.find((numberInfo) => numberInfo.Id === Number(id))?.Number

    if (phoneNumber) {
      country.CountryPhoneNumber = phoneNumber
    }

    store.setCurrentCountry(country)
    const currency = getCurrencyById(Number(country.CurrencyId)) as Currency
    store.setCurrentCurrency(currency)
  }

  // Function to handle currency change
  const changeHeaderCurrencyById = async (id: number | string) => {
    const currency = getCurrencyById(Number(id)) as Currency
    store.setCurrentCurrency(currency)
  }

  const setLanguage = (language: Language) => {
    if (!language) {
      console.error('Language not found')
      return
    }

    store.setCurrentLanguage(language)

    // Section after is handling shop dynamic routes(shop routes translation)
    if (routeStore.currentRoute?.path === route.path) {
      const urlLanguageByLocale = routeStore.getUrlLanguageByLocale(language.UrlCode)
      routeStore.addShopRoutes([urlLanguageByLocale])

      router.push(urlLanguageByLocale.path)
    } else {
      router.push(switchLocalePath(language.UrlCode))
    }
  }

  // Function to handle language change
  const changeLanguageById = (id: string | number) => {
    const language = getLanguageById(Number(id)) as Language
    setLanguage(language)
  }

  return {
    countries,
    currencies,
    changeCountryById,
    changeLanguageById,
    changeHeaderCurrencyById,
  }
}
