<template>
  <form
    data-di-form-track
    data-di-form-id=""
    novalidate
    class="online-appointments--step5 lg:mr-10"
    @submit.prevent="$emit('nextStep', { appointmentData: appointmentData })">

    <button type="button" class="mb-6">
        <!-- <i class="icon77 icon77-chevron -left"></i> -->
        <span class="_display-5">
            {{$t('book-an-appointment', 'Book an Appointment')}}
        </span>
    </button>
    
    <ui-old-accordion v-model="current" :multiple="false" :show-arrow="true" title-class="body-12-aa-cap">
        <ui-old-accordion-item value="1" opened>
            <template #label="{ visible }">
                <i v-if="(appointmentData.notsure || appointmentData.stoneType.length)" class="icon77shop ic_tick_green _float-left _mr-2"></i>

                <template v-if="!visible && (appointmentData.notsure || appointmentData.stoneType.length)">
                    <template v-if="appointmentData.notsure">
                        {{ $t('notsure', 'I\'m not sure') }}
                    </template>
                    <template v-else>
                        {{ [...appointmentData.stoneType.map(st => st.value),
                            ...appointmentData.shapes.map(st => st.value)
                           ].map(v => $t(...v)).join(', ') }}
                    </template>
                </template>
                    
                <template v-else>
                    {{ $t('appoint-form-stoneShape-preference', 'Stone Preferences') }}
                </template>
            </template>

            <div :class="{disabled: appointmentData.notsure}">
                <div class="_form-row">
                    <div v-for="st in stoneTypes" :class="st.class || '_col'">
                        <h3 class="body-12-aa-cap">{{ $t(...st.label) }}</h3>
                        <label v-for="a in st.items">
                            <input v-model="appointmentData.stoneType" type="checkbox" :value="a">
                            <span class="body-14-aa">{{ $t(...(a.value || a.label)) }}</span>
                            <i :class="`icon77 icon77-${a.icon} float-right`"></i>
                        </label>
                    </div>
                </div>

                <hr>

                <div class="_form-row">
                    <div v-for="a in shapes" class="_col-6">
                        <label>
                            <input v-model="appointmentData.shapes" type="checkbox" :value="a">
                            <span class="body-14-aa">{{ $t(...a.value) }}</span>
                        </label>
                    </div>
                </div>
            </div>

            <hr>

            <label>
                <input type="checkbox" v-model="appointmentData.notsure" class="rounded">
                <span class="body-14-aa">{{ $t('notsure', 'I\'m not sure') }}</span>
            </label>
        </ui-old-accordion-item>

        <ui-old-accordion-item value="2">
            <template #label="{ visible }">
                <i v-if="appointmentData.needIt" class="icon77shop ic_tick_green _float-left _mr-2"></i>

                <template v-if="!visible && appointmentData.needIt">
                    {{ $t(...appointmentData.needIt.label) }}
                </template>
                    
                <template v-else>
                    {{ $t('appoint-form-needIt-step', 'Need it in the next') }}
                </template>
            </template>

            <label v-for="ni in needItList">
                <input v-model="appointmentData.needIt" type="radio" :value="ni" class="rounded" @change="current = 3">
                <span class="body-14-aa">{{ $t(...ni.label) }}</span>
            </label>
        </ui-old-accordion-item>

        <ui-old-accordion-item value="3" @activated="priceOK = true">
            <template #label="{ visible }">
                <i v-if="priceOK" class="icon77shop ic_tick_green _float-left _mr-2"></i>

                <template v-if="!visible && priceOK">
                    {{ currency(appointmentData.price[0]) }} - {{ currency(appointmentData.price[1]) }}
                </template>
                    
                <template v-else>
                    {{ $t('appoint-form-priceRange-step', 'Price range') }}
                </template>
            </template>

            <div class="_row _py-2">
                <div class="_col">
                    <select v-model="appointmentData.price[0]" class="_form-control">
                        <option v-for="p in priceOptions.filter(p => p < appointmentData.price[1])" :value="p">{{ currency(p) }}</option>
                    </select>
                </div>

                <VueSlider
                    v-model="appointmentData.price"
                    :data="priceOptions"
                    :height="2" tooltip="none"
                    :marks="false" adsorb- :minRange="1"
                    class="_col self-center" />
                
                <div class="_col">
                    <select v-model="appointmentData.price[1]" class="_form-control">
                        <option v-for="p in priceOptions.filter(p => p > appointmentData.price[0])" :value="p">{{ currency(p) }}</option>
                    </select>
                </div>
            </div>
        </ui-old-accordion-item>

        <ui-old-accordion-item value="4">
            <template #label="{ visible }">
                <i v-if="notes" class="icon77shop ic_tick_green _float-left _mr-2"></i>

                <template v-if="!visible && notes">
                    {{ notes }}
                </template>
                    
                <template v-else>
                    {{ $t('appoint-form-notes', 'Notes') }}
                </template>
            </template>

            <textarea
                v-model="appointmentData.notes"
                :placeholder="$t('appoint-form-notes-placeholder-new', 'Enter any additional stone preferences (carat size, cut, clarity, etc), favourite jewellery styles or any design ideas you may have.')"
                class="body-14-aa w-full h-[120px]">
            </textarea>
        </ui-old-accordion-item>
    </ui-old-accordion>

    <button type="submit" :disabled="!(appointmentData.notsure || appointmentData.stoneType.length) || !appointmentData.needIt || !priceOK" class="_cta-white _btn-block _my-3 continue" data-cy="continue-button">
        {{$t('appoint-form-last-step-submitBtn', 'Submit request')}}
    </button>
  </form>
</template>

<style lang="scss">
.online-appointments--step5 {
    .accordion-item {
        border: 1px solid #DDDDDD;
        margin-bottom: 10px;

        &.-opened {
            border-color: #000;
        }

        .disabled {
            opacity: 0.6;
            pointer-events: none;
        }
        
        .accordion-item-label {
            position: relative;
            margin-bottom: -1px;
            padding: 10px 50px 10px 20px;
            border-top: none;
    
            .accordion-item-arrow {
                position: absolute;
                margin: 15px 25px;
                right: 0;
                top: 0;
                // width: 10px;
                // height: 10px;
                // transform: rotate(135deg);
                // box-shadow: 1px -1px 0.5px 0.8px;
            }
        }
        
        // .chevron-up {
        //     transform: rotate(-45deg);
        //     margin: 12px 25px;
        // }

        .accordion-item-content {
            padding: 10px 20px;
        }
        
    }

    .accordion-item-content {
        label {
            display: block;
            margin-bottom: 5px;
            line-height: 25px;
        }

        hr {
            margin: 10px -20px;
        }

        input[type="checkbox"],
        input[type="radio"] {
            display: none;

            + span:before {
                content: "";
                width: 15px;
                height: 15px;
                display: inline-block;
                border: 1px solid;
                margin-right: 10px;
                vertical-align: text-bottom;
            }
            
            &:checked + span:before {
                background-color: #000;
                background-image: url(/images/shop/icons/full_tick.svg);
                background-position: 50%;
                background-size: 19px;
            }

            &.rounded + span:before {
                border-radius: 100%;
            }
        }
    }

    .vue-slider-dot-handle {
        border: 1px solid;
        box-shadow: none;
    }

    .vue-slider-process {
        background-color: #000;
    }
}
</style>

<script setup>
import VueSlider from 'vue-3-slider-component'
import { ExchangeRate } from '~/api/app'
import { useNewSettingsStore } from '~/store/newSettingsStore'

const { getCurrentCurrencySymbol } = useNewSettingsStore()

const exchangeRate = await ExchangeRate()

const stoneTypes = [
    {
        label: ['diamonds', 'diamonds'],
        class: '_col-7 _col-md-6',
        items: [
            {
                id: 1,
                type: 'nat',
                value: ['natural-diams', 'Natural Diamond'],
                label: ['natural', 'Natural'],
                icon: 'diamond_round'
            },
            {
                id: 3,
                type: 'nat',
                value: ['lab-grown-diams', 'Lab-grown Diamond'],
                label: ['lab-grown', 'Lab-grown'],
                icon: 'diamond_round'
            },
            {
                id: 198,
                type: 'fancy',
                value: ['yellow-coloured', 'Yellow Diamond'],
                label: ['yellow-coloured', 'Yellow/Coloured'],
                icon: 'diamond_round_yellow'
            },
        ]
    },
    {
        label: ['gemstones', 'Gemstones'],
        items: [
            {
                id: 366,
                type: 'gem',
                value: ['ruby', 'Ruby'],
                // label: 'Ruby',
                icon: 'ruby_round'
            },
            {
                id: 371,
                type: 'gem',
                value: ['emerald', 'Emerald'],
                // label: 'Emerald',
                icon: 'emerald_round'
            },
            {
                id: 367,
                type: 'gem',
                value: ['sapphire', 'Sapphire'],
                // label: 'Sapphire',
                icon: 'sapphire_round'
            },
        ]
    },
]

const shapes = [
  {
    id: 1,
    value: ['round', 'round'],
  },
  {
    id: 2,
    value: ['princess', 'princess'],
  },
  {
    id: 3,
    value: ['emerald', 'emerald'],
  },
  {
    id: 4,
    value: ['asscher', 'asscher'],
  },
  {
    id: 5,
    value: ['pear', 'pear'],
  },
  {
    id: 6,
    value: ['radiant', 'radiant'],
  },
  {
    id: 7,
    value: ['oval', 'oval'],
  },
  {
    id: 8,
    value: ['cushion', 'cushion'],
  },
  {
    id: 9,
    value: ['heart', 'heart'],
  },
  {
    id: 10,
    value: ['marquise', 'marquise'],
  },
];

const needItList = [
    {
        id: 3,
        label: ['less-than-one-month', 'Less than 1 month']
    },
    {
        id: 0,
        label: ['twomonths', '2 months']
    },
    {
        id: 1,
        label: ['sixmonths', '6 months']
    },
    {
        id: 2,
        label: ['twelvemonths', '12 months']
    },
]

const priceOptions = 
    [ 300, 500, 800, 1000, 1500,  2000, 2500, 3000, 4000, 5000, 6000, 7000, 8000, 10000, 12000, 14000, 16000, 18000, 20000, 30000, 40000, 50000, 60000, 75000 ]
    .map(p => parseInt(p * exchangeRate))

const current = ref(1)

const appointmentData = ref({
    stoneType: [],
    shapes: [],
    notsure: false,
    needIt: false,
    price: [
        priceOptions[0],
        priceOptions[priceOptions.length - 1]
    ],
    priceOK: false,
    notes: '',
});


const currency = (value) => {
    const { currency } = useShop()
    return currency(value, getCurrentCurrencySymbol, 0)
}

//   export default {
//     data() {
//       return {
//         current: 2
//       };
//     },

//     validations() {
//       return {
//       };
//     },

//     computed: {
//     },

//     methods: {
//       submit() {
//           this.$emit('nextStep', { userData: this.user })
//       },
//     },

//     mounted() {
//     },
//   };
</script>
