export default {
  OK: 1,
  NOK: 2,
  USER_ALREADY_EXISTS: 3,
  USER_DONT_EXIST: 4,
  CONFIRMATION_EMAIL_SENT: 5,
  LIST_FULL: 6,
  OK_IS_CUSTOMER: 7,
  USER_ALREADY_EXIST_IS_CUSTOMER: 8,
  CONFIRMATION_EMAIL_SENT_IS_CUSTOMER: 9,
}
