<template>
  <div class="absolute h-full right-0 pointer-events-none">
    <div class="-mt-12 mr-7 sticky bottom-0 left-full right-0 z-20 inline-flex flex-col pointer-events-auto" :class="offsetClass">
      <LayoutBackToTop />
      <LayoutChat />
    </div>
  </div>
</template>

<script setup>
const { showCookieBar } = useLayout()

const offsetClass = computed(() => (showCookieBar.value ? 'top-[calc(85%-60px)] sm:top-[calc(85%-40px)]' : 'top-[85%]'))
</script>
