<template>
  <h3 class="h5 uppercase mt-1 mb-3">
    {{ $t('footer-lets-keep-in-touch', "Let's keep in touch") }}
  </h3>

  <div class="form-group mb-4" data-ga4-form="NewsLetter Form">
    <input
      id="footerEmail"
      v-model="email"
      type="email"
      name="email"
      :placeholder="$t('email-address', 'Email Address')"
      class="form-input"
      data-cy="email-address"
    />
    <button
      type="button"
      class="_btn"
      aria-label="display newsletter"
      data-cy="display-newsletter-button"
      @click="displayNewsletter"
    >
      <i class="icon77 icon77-arrow-right"></i>
    </button>
  </div>

  <h3 class="h5 uppercase hidden lg:block mt-5 mb-2">
    {{ $t('follow-us', 'follow us') }}
  </h3>

  <div class="flex justify-between mb-8 mt-4 xl:pr-6">
    <a href="http://instagram.com/77diamonds" rel="noopener" class="ig" target="_blank" title="Instagram">
      <span class="icon77 icon77-instagram"></span>
    </a>
    <a href="https://www.facebook.com/77Diamonds" rel="noopener" class="fb" target="_blank" title="Facebook">
      <span class="icon77 icon77-facebook"></span>
    </a>
    <a href="https://twitter.com/77Diamonds" rel="noopener" class="tw" target="_blank" title="Twitter">
      <span class="icon77 icon77-twitter"></span>
    </a>
    <a href="http://www.pinterest.com/77diamonds/" rel="noopener" class="pi" target="_blank" title="Pinterest">
      <span class="icon77 icon77-pinterest"></span>
    </a>
    <a href="https://www.linkedin.com/company/77-diamonds" rel="noopener" class="pi" target="_blank" title="Linkedin">
      <span class="icon77 icon77-linkedin"></span>
    </a>
    <a href="https://www.youtube.com/c/77diamonds" rel="noopener" class="pi" target="_blank" title="youtube">
      <span class="icon77 icon77-youtube"></span>
    </a>
    <a href="https://www.tiktok.com/@77diamondsltd" rel="noopener" class="pi" target="_blank" title="tiktok">
      <span class="icon77 icon77-social-tiktok-24px"></span>
    </a>
  </div>

  <Popup v-model="newsletterPopup" hide-footer container-class="newsletter-modal md:w-[740px] overflow-y-auto p-0">
    <div class="grid77 gap-0">
      <div class="col-span-12 sm:col-span-6 imageHolder p-0 hidden sm:block">
        <Image77 path="/images/Campaign/2022/DiamondEarrings" image-class="h-full w-full" m="/desktop-mayfair_x1.jpg" />
      </div>
      <div class="col-span-12 sm:col-span-6 signup-form p-4 md:p-5">
        <div class="text-content mb-4">
          <h3 class="popup-title heading-4 mb-1">
            {{ $t('sign-up-to-our-newsletter', 'Sign up to our newsletter') }}
          </h3>
          <p class="popup-description body-14-aa">
            {{
              $t(
                'sign-up-description-new-2',
                'Join our community of jewellery lovers, and receive once a week special offers, news and product launches straight to your inbox.',
              )
            }}
          </p>
        </div>

        <div data-di-form-track data-di-form-id="sales-popup" class="formFields mt-6">
          <div class="fieldHolder floating-label" :class="{ hasError: !fullNameValidation }">
            <input
              id="signupPopupFullName"
              v-model="fullName"
              type="text"
              placeholder=" "
              class="_form-control validName required new floating-input"
              data-cy="full-name"
            />
            <label>{{ $t('full-name', 'Full Name') }}*</label>
            <p v-if="!fullNameValidation" class="errorMessage">
              <span>{{ $t('footer-validation-empty-full-name', 'Please enter your Full Name') }}</span>
            </p>
          </div>
          <div class="fieldHolder floating-label my-6" :class="{ hasError: !emailValidation }">
            <input
              id="signupPopupEmailAddress"
              v-model="email"
              type="text"
              placeholder=" "
              class="_form-control validEmail required new floating-input"
              data-cy="email-address"
            />
            <label>{{ $t('footer-email-address', 'Email Address') }}*</label>
            <p v-if="!emailValidation" class="errorMessage">
              <span>{{ $t('footer-validation-empty-email', 'Please enter your Email') }}</span>
            </p>
          </div>

          <div class="my-6 gender-selection">
            <label class="mr-4">
              <input
                v-model="gender"
                type="radio"
                name="signupPopupGender"
                value="male"
                data-ga4-label="Gender"
                data-ga4-pii
                data-cy="male-option"
              />
              <span class="gender">{{ $t('male', 'Male') }}</span>
            </label>

            <label class="">
              <input
                v-model="gender"
                type="radio"
                name="signupPopupGender"
                value="female"
                data-ga4-label="Gender"
                data-ga4-pii
                data-cy="female-option"
              />
              <span class="gender">{{ $t('female', 'Female') }}</span>
            </label>
          </div>

          <Button77
            id="SignupPopupButton"
            class="mb-6 signup-button relative max-h-[44px]"
            block
            data-cy="subscribe-button"
            :class="{ finished, active: loading, failed }"
            @click="subscribe"
          >
            <span v-if="loading" class="active"><i class="icon77 icon77-refresh"></i></span>
            <span v-else-if="finished" class="check"><i class="icon77 icon77-double_tick"></i></span>
            <span v-else-if="failed" class="error"><i class="icon77 icon77-error"></i></span>
            <span v-else class="submit"> {{ $t('footer-subscribe', 'Subscribe') }}</span>
          </Button77>

          <div class="consent-container">
            <p
              class="consent-text body-10-aa text-muted"
              v-html="
                $t(
                  'sign-up-newsletter-warning-newtext',
                  {
                    termsLink: translateURL('/NewApp/Default.aspx?app=terms'),
                    privacyPolicyLink: translateURL('/Footer/Privacy.aspx'),
                  },
                  `By submitting your details, you consent to
              receive marketing emails from 77 Diamonds. You can unsubscribe anytime from this. For further information,
              please read our <a href='{privacyPolicyLink}' target='_blank'>privacy policy</a> and
              <a href='{termsLink}' target='_blank'>terms and conditions</a>.`,
                )
              "
            ></p>
          </div>
        </div>
      </div>
    </div>
  </Popup>

  <!-- alertify replacement -->
  <Popup v-if="alertPopup" v-model="alertPopup" hide-footer container-class="md:w-[440px] p-0">
    <div class="flex flex-col justify-center p-8">
      <div class="text-center mb-4" v-html="alertMessage"></div>
      <Button77 variant="yellow" @click="closeAlert">{{ $t('footer-ok', 'OK') }}</Button77>
    </div>
  </Popup>
</template>

<script setup lang="ts">
import responseTypes from '@/constants/websiteEnums/responseTypes'
import Popup from '~/components/ui/Popup.vue'
import Button77 from '@/components/ui/Button77.vue'
import Image77 from '~/components/ui/Image77.vue'
import { useNewSettingsStore } from '~/store/newSettingsStore'
// api
import { newsletterSignup } from '~/api/marketing'

const { currentCountry, currentLanguage } = storeToRefs(useNewSettingsStore())
const { t } = useI18n()

const loading = ref(false)
const failed = ref(false)
const finished = ref(false)
const gender = ref('male')

const fullName = ref('')
const fullNameValidation = ref(true)
const email = ref('')
const emailValidation = ref(true)

const newsletterPopup = ref(false)

const alertPopup = ref(false)
const alertMessage = ref('')

const displayNewsletter = () => {
  newsletterPopup.value = true
}

const closeAlert = () => {
  alertPopup.value = false
}

const subscribe = async (e) => {
  finished.value = false
  failed.value = false

  const isMale = gender.value === 'male'

  emailValidation.value = !!email.value
  fullNameValidation.value = !!fullName.value
  const validForm = emailValidation.value && fullNameValidation.value

  if (validForm) {
    try {
      loading.value = true
      const result = await newsletterSignup({
        email: email.value,
        firstName: fullName.value,
        isMale: isMale,
        countryId: currentCountry.value?.Id,
        languageId: currentLanguage.value?.Id,
        eventName: 'FooterNewsletterSignup',
        url: window.location.href,
      })
      if (result === 'OK' || result === responseTypes.OK) {
        // TODO: ga analytics (verify with eric)
        //                 var hyphenated_registration = '/subscribed';
        //                 var registration_type = 'Subscribed | Newsletter ' + from;
        //                 gglTrack.NewsletterSignup(hyphenated_registration, registration_type);
        alertMessage.value =
          t('footer-newsletter-success-generictext-new1', 'Congrats! You’re now an exclusive member of 77 Diamonds.') +
          '<br><br><strong>' +
          t('footer-connect-with-us', 'Connect With Us') +
          "</strong><div class='mt-4'><a data-social='instagram' data-page='Newsletter Signup' href='http://instagram.com/77diamonds' rel='noopener' class='ig _mr-2' target='_blank' title='Instagram'><span class='icon77 icon77-instagram'></span></a><a data-social='facebook' data-page='Newsletter Signup' href='https://www.facebook.com/77Diamonds' rel='noopener' class='fb' target='_blank' title='Facebook'><span class='icon77 icon77-facebook'></span></a></div>"
        finished.value = true
      }
      if (
        result === 'INVALID_EMAIL' ||
        result === responseTypes.USER_ALREADY_EXISTS ||
        result === responseTypes.USER_ALREADY_EXIST_IS_CUSTOMER
      ) {
        alertMessage.value = t('footer-newsletter-user-exists-generictext', 'A user with this email already exists.')
        finished.value = true
      }
      if (result == 'NOK' || result === responseTypes.NOK) {
        alertMessage.value = t('An error has ocurred.')
        failed.value = true
      }
    } catch (e) {
      alertMessage.value = t('An error has ocurred.')
      failed.value = true
    } finally {
      loading.value = false
      alertPopup.value = true
      fullName.value = ''
      email.value = ''
    }
  }
}
</script>
<style scoped lang="scss">
#footerEmail::placeholder {
  font-size: 12px;
}

input[type='radio'] {
  accent-color: black;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.fieldHolder {
  &.floating-label {
    position: relative;
    /*margin-bottom: 24px;*/
    height: 44px;
    border: 1px solid #e4e4e4;
    /*border-radius: 4px;*/
    background-color: #ffffff;

    .floating-input {
      &:focus,
      &:not(:placeholder-shown) {
        & ~ label {
          top: 0;
          transform: translateY(4px);
          color: #666666;
          font-family: 'Plain Light';
          font-size: 12px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 20px;
        }
      }
    }

    &:focus-within {
      border: 1px solid #666666;
    }

    label {
      color: #666666;
      font-family: 'Plain Light';
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 24px;
      position: absolute;
      pointer-events: none;
      left: 0px;
      padding: 0 12px;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }

    &.hasError {
      border: 1px solid #a9201e;
      margin-bottom: 40px;

      label {
        color: #a9201e !important;
      }
    }

    .errorMessage {
      position: absolute;
      bottom: 0;
      transform: translateY(110%);
      margin: 0;

      span {
        color: #a9201e;
        font-family: 'Plain Light';
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 16px;
      }
    }
  }

  input {
    font-family: 'Plain Light';
    color: #0d0900;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    height: 28px;
    border: 0;
    outline: none;
    bottom: 0;
    position: absolute;

    &:-webkit-autofill {
      -webkit-background-clip: text;
      background-clip: text;
    }

    &:focus {
      outline: none;
      border: 0;
      box-shadow: none;
    }

    &::placeholder {
      color: #666666;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
}

.genderWrapper {
  -webkit-box-shadow:
    0 0 0 4px white,
    0 0 0 5px #e4e4e4;
  box-shadow:
    0 0 0 4px white,
    0 0 0 5px #e4e4e4;
  background: #fff;
  margin: 24px auto;

  input + span {
    font-family: 'Plain Light';
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    text-align: center;
  }

  .toggler {
    background-color: #f2f2f2;
    color: black;

    span {
      font-family: 'Plain Light';
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      text-align: center;
    }

    &.active {
      background-color: #0d0900;
      color: white;
    }
  }
}

.signup-form input[type='radio'] {
  vertical-align: middle;
  margin: -2px 5px 0 0;
  width: 16px;
  height: 16px;
  accent-color: #0d0900;
}
.consent-container {
  font-family: 'Plain Light';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #666666;

  p {
    margin: 0;
  }

  .consent-text {
    cursor: pointer;
  }

  .more-consent-text {
    visibility: hidden;
  }

  a {
    text-decoration: underline;
    color: #666666;
  }

  &.constent-toggle {
    .more-consent-text {
      visibility: unset;
    }

    .down-arrow {
      transform: rotate(180deg);
    }
  }
}

.signup-button {
  position: relative;
  /*pdisplay: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0rem 3.125rem;
    background-color: #0D0900;
    border: 1px solid transparent;
    border-radius: 0;
    color: #FFFFFF;
    font-weight: 300;
    text-transform: uppercase;
    font-family: "Plain Light";
    overflow: hidden;
    width: 100%;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 20px;
    text-align: center;
    height: 44px;
    transition: all 0.4s linear;*/

  .loading,
  .check,
  .error {
    display: none;
  }

  &.active {
    background-color: #2ecc71;

    &:before {
      width: 100%;
      transition: width 3s linear;
    }

    .loading {
      i {
        animation: loading 500ms linear infinite;
      }
    }
  }

  &.finished {
    background-color: lighten(#2ecc71, 10%);

    .submit {
      display: none;
    }

    .loading {
      display: none;
    }

    .check {
      display: block !important;
      font-size: 24px;
      animation: scale 0.5s linear;

      i {
        transform-origin: center center;
      }
    }
  }

  &.failed {
    background-color: lighten(#cc332e, 10%);

    .submit {
      display: none;
    }

    .loading {
      display: none;
    }

    .check {
      display: none;
    }

    .error {
      display: block !important;
      font-size: 24px;
      animation: scale 0.5s linear;

      i {
        transform-origin: center center;
      }
    }
  }

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: lighten(#2ecc71, 10%);
  }
}
</style>
