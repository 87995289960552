import { useLayoutStore } from '~/store/layoutStore'

// layout class constants
const COOKIE_BAR_CLASS = 'hasCookieNotice'

export const useLayout = () => {
  const { showCookieBar } = storeToRefs(useLayoutStore())
  watch(
    showCookieBar,
    (val) => {
      if (document) {
        if (val) {
          document.body.classList.add(COOKIE_BAR_CLASS)
        } else {
          document.body.classList.remove(COOKIE_BAR_CLASS)
        }
      }
    },
    { immediate: true },
  )

  return {
    showCookieBar,
  }
}
