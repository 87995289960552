<template>
  <div class="basis-1/3 flex gap-2 items-center justify-end">
    <div
      class="bookAppointment font-plain color-charcoal _d-lg-flex _align-items-lg-center"
      @click="openBookAppointment"
    >
      <i class="icon77 icon77-calendar"></i>
      <p class="bookAppointment-title hidden lg:inline">
        {{ $t('book-an-appointment', 'Book an Appointment') }}
      </p>
    </div>
    <button
      id="showroom-popover-icon"
      type="button"
      aria-label="open showroom list"
      class="_btn icon77 icon77-nav_location hidden md:inline"
      @click="$emit('open-closest-showroom')"
    ></button>
    <HeaderAuth />
    <NuxtLink
      v-if="!isBasketPage"
      :title="$t('shopping-bag', 'Shopping Bag')"
      class="toggle-bag cursor-pointer"
      data-cy="shopping-bag-icon"
      @click="$emit('open-shoppingbag')"
    >
      <span class="shoppingBagIcon icon77 icon77-nav_bag"></span>

      <span v-if="itemsCount !== 0" class="bagCountHolder body-14-aa pl-1">{{ itemsCount }}</span>
    </NuxtLink>
  </div>

  <ui-popup v-model="bookAppointment" hide-footer overlayClass="book-appointment" container-class="lg:w-[1024px] overflow-y-auto p-0">
    <online-appointments @close="bookAppointment = false"></online-appointments>
  </ui-popup>
</template>

<script setup lang="ts">
import HeaderAuth from './HeaderAuth.vue'
import { useShoppingBagStore } from '~/store/shoppingBagStore'
const { itemsCount } = storeToRefs(useShoppingBagStore())

const route = useRoute()

const isBasketPage = computed(() => {
  return route.path === '/shop-bag' // TODO: replace with actual route name
})

const bookAppointment = ref(false)

const openBookAppointment = () => {
  // TODO: implement book appointment dialog
  bookAppointment.value = true
}
</script>

<style scoped lang="scss">
.bookAppointment {
  cursor: pointer;

  .icon77 {
    @media (max-width: 1024px) {
      display: inline-block;
      margin-right: 4px;
    }

    @media (max-width: 767px) {
      margin-right: 0px;
    }
  }

  .bookAppointment-title {
    margin: auto 10px;
    font-size: 13px;
    line-height: initial;
    letter-spacing: 0px;
  }
}

.bagCountHolder {
  vertical-align: -2px;
}
</style>
