<template>
    <div :id="CurrentStep" class="_d-flex _flex-column-reverse">
        <div v-if="CurrentStep == 'firstStep'" class="chatNow">
            <div class="_text-center _p-2 _p-md-4 _p-lg-6">
                <h3 class="heading-5 _mb-2">
                    {{$t('appoint-form-chatnow-text1', 'Have a Quick Question?')}}
                </h3>
            
                <p class="_p _mb-3">
                    {{$t('appoint-form-chatnow-text2', 'Our consultants are happy to help 24/7 through live chat.')}}
                </p>

                <button type="button" class="_cta-black _btn-block">
                    {{$t('appoint-form-chatnow-btn', 'Chat Now')}}
                </button>
            </div>
        </div>
        <div class="_row _no-gutters _mx-2 _my-4">
            <div class="_col-5 _col-lg-6 _d-none _d-md-block _text-center">
                <img  :src="`/images/img-2023/BookAppImages/${bookAppImage}`" class="_pr-3">
            </div>
            <div class="_col pr-lg-6">
                <template v-if="CurrentStep == 'firstStep'">
                    <online-appointments-steps-step1 @nextStep="setStep('secondStep')" />
                </template>

                <template v-if="CurrentStep == 'secondStep'">
                    <online-appointments-steps-step2
                        @nextStep="selectShowroom"
                        @prevStep="setStep('firstStep')" />
                </template>

                <template v-if="CurrentStep == 'thirdStep'">
                    <online-appointments-steps-step3
                        :disabledDates="disabledDates"
                        :selectedInterest="selectedInterest"
                        :selectedDate="selectedDate"
                        :preferedTime="preferedTime"
                        @nextStep="selectDate"
                        @prevStep="setStep('secondStep')" />
                </template>
                    
                <template v-if="CurrentStep == 'fourthStep'">
                    <online-appointments-steps-step4
                        @nextStep="selectUser"
                        @prevStep="setStep('thirdStep')" />
                </template>
                    
                <template v-if="CurrentStep == 'lastStep'">
                    <online-appointments-steps-step5
                        @nextStep="setAppointment" />
                </template>
                    
                <template v-if="CurrentStep == 'confirmation'" data-cy="confirmation">
                    <div id="confirmation" class="p-5 mt-5">
                        <h3 class="heading-5">
                            {{$t('appoint-form-confirmation-title1', { name: 'eric' }, 'Thanks, {name}!')}}
                        </h3>
                        
                        <h3 class="heading-5">
                            {{$t('appoint-form-confirmation-title2', 'We’ll contact you soon to confirm your appointment.')}}
                        </h3>

                        <p class="body-14-aa">
                            {{$t('appt-form-confirmation-message-newer', 'One of our experts will email you within the next 24 hours on weekdays to confirm your booking and prepare for your upcoming consultation.')}}
                        </p>
                    
                        <p class="body-14-aa">
                            {{$t('appoint-form-confirmation-text2', 'In the meantime, check out our collections for ideas or read our helpful guides about diamond and gemstone jewellery.')}}
                        </p>

                        <div class="grid grid-cols-2 gap-2 lg:my-9" @click="$emit('close')">
                            <NuxtLink :href="translateURL('/ShopNew/Default.aspx?category=6')" id="c-collections" class="_cta-black no-underline">
                                {{$t('appoint-form-confirmation-btn1', 'browse collections')}}
                            </NuxtLink>
                            <NuxtLink :href="translateURL('/Education/DiamondEducation.aspx')" id="c-guide" class="_cta-black no-underline">
                                {{$t('appoint-form-confirmation-btn2', 'read guides')}}
                            </NuxtLink>
                        </div>

                        <div class="socials _my-3 _text-center">
                            <a data-social="instagram" data-page="Booking Confirmation Popup" href="http://instagram.com/77diamonds" rel="noopener" class="ig _mr-2" target="_blank" title="Instagram"><span class="icon77 icon77-instagram"></span></a>
                            <a data-social="facebook" data-page="Booking Confirmation Popup" href="https://www.facebook.com/77Diamonds" rel="noopener" class="fb _mr-2" target="_blank" title="Facebook"><span class="icon77 icon77-facebook"></span></a>
                            <a data-social="twitter" data-page="Booking Confirmation Popup" href="https://www.twitter.com/77Diamonds" rel="noopener" class="twt _mr-2" target="_blank" title="Twitter"><span class="icon77 icon77-twitter"></span></a>
                            <a data-social="pinterest" data-page="Booking Confirmation Popup" href="https://www.pinterest.com/77Diamonds" rel="noopener" class="pin _mr-2" target="_blank" title="Pinterest"><span class="icon77 icon77-pinterest"></span></a>
                            <a data-social="linkedin" data-page="Booking Confirmation Popup" href="https://www.linkedin.com/company/77-diamonds" rel="noopener" class="lin _mr-2" target="_blank" title="LinkedIn"><span class="icon77 icon77-linkedin"></span></a>
                            <a data-social="youtube" data-page="Booking Confirmation Popup" href="https://www.youtube.com/c/77Diamonds" rel="noopener" class="yt _mr-2" target="_blank" title="YouTube"><span class="icon77 icon77-youtube"></span></a>
                            <a data-social="tiktok" data-page="Booking Confirmation Popup" href="https://www.tiktok.com/@77diamondsltd" rel="noopener" class="tt" target="_blank" title="TikTok"><span class="icon77 icon77 icon77-social-tiktok-24px"></span></a>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <!-- alertify replacement -->
    <ui-popup v-if="ErrorMessage" v-model="ErrorMessage" hide-footer container-class="md:w-[440px] p-0">
      <div class="flex flex-col justify-center p-8">
        <div class="text-center mb-4" v-html="ErrorMessage"></div>
        <ui-button77 variant="yellow" @click="closeAlert">{{ $t('ok', 'OK') }}</ui-button77>
      </div>
    </ui-popup>
</template>

<style lang="scss">
    .modal-mask.book-appointment {
        align-items: flex-start;
        padding-top: 40px;
    }

    .scrollable-container {
        // @media screen and (min-width: 768px) {
            // position: absolute;
            height: 90%;
            max-height: 584px;
            width: 100%;
            overflow: auto;
        // }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
            width: 3px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0,0,0,.2);
        }
    }

    .chatNow {
        background-color: #F7F1E5;
        padding: 10px;

        ._btn-block {
            max-width: 220px;
            margin: auto;
        }
    }

    #footerContacts.appointmentPopup .modal #firstStep {
        padding-top: 50px;
    }

    ._form-group.floating {
        position: relative;

        &:not(.-sticky) ::placeholder {
            opacity: 0;
        }

        > label {
            top: 13px;
            position: absolute;
            transition: 0.2s;
            pointer-events: none;
            color: #666;
            margin: 0 16px;

            font-size: 14px;
            white-space: nowrap;
        }

        input._form-control {
            // padding: 30px 16px 16px;
            padding-top: 28px;
            padding-bottom: 14px;
        }

        select._form-control {
            padding: 14px 35px 0px 15px;

            appearance: none;
            background-image: url(/images/shop/icons/chevron-down.svg);
            background-size: 14px;
            background-repeat: no-repeat;
            background-position: center right 15px;

            option {
                font-family: 'Nunito Sans';
            }
        }
        
        &.-sticky ._form-control,
        ._form-control:focus,
        select._form-control:valid,
        input._form-control:not(:placeholder-shown) {
            ~ label {
                top: 6px;
                font-size: 12px;
            }
        }

        .focused-input {
          top: 3px;
          font-size: 12px;
        }
    }

    ._form-group ._text-danger p {
        margin: 2px 0px;
        font-size: 12px;
        color: #a9201e;
    }

    ._form-group._form-group--error ._form-control {
        border: 1px solid #a9201e;
    }

    .gdprCheckboxHolder {
        label {
            display: flex;
            align-items: flex-start;
            margin: 0;
            cursor: pointer;
            padding: 0;
        }

        a {
            text-decoration: underline;
        }

        input[type=checkbox] + label:before {
            content: '';
            // display: inline-block;
            min-width: 18px;
            height: 18px;
            border: 1px solid #0d0900;
            margin-right: 7px;
            translate: 0 5px;
        }

        input:checked + label:before {
            background-image: url(/images/shop/icons/full_tick.svg);
            background-color: #000;
            background-position: 50%;
            background-size: 19px;
            background-repeat: no-repeat;
            border-color: #000;
        }
    }
</style>

<script setup lang="ts">

import { useOnlineAppointments } from './store'

let { ErrorMessage } = storeToRefs(useOnlineAppointments());
let { closeAlert } = useOnlineAppointments();

let { CurrentStep, Showrooms, CurrentShowroom } = storeToRefs(useOnlineAppointments());
let { setStep, getShowrooms, selectShowroom, selectDate, selectUser, setAppointment } = useOnlineAppointments();

let bookAppImage = computed(() => {
    if (CurrentStep.value == 'firstStep' || CurrentStep.value == 'secondStep')
        return 'Step-0_x1.jpg';
    else
        return 'Step-2-London_x1.jpg';
})

callOnce(getShowrooms);

onMounted(() => {
    CurrentStep.value = 'firstStep';
})

</script>

<!--script>
    import Step1 from './steps/Step1';
    import Step2 from './steps/Step2';
    import Step3 from './steps/Step3';
    import Step4 from './steps/Step4';
    import Step5 from './steps/Step5';
    
    export default {
        components: { Step1, Step2, Step3, Step4, Step5 },

        data() {
            return {
                CurrentStep: 'firstStep',
                showrooms: [],
                currentShowroom: null,
                selectedInterest: null,
                selectedDate: null,
                preferedTime: null,
                userData: null
                // firstAvailableDate
            }
        },

        watch: {
            user() {
                debugger;
            },

            user2: {
                handler: function (newValue) {
                    this.$store.commit('updateUser', this.user);
                },
                // Tell vue to do a deep watch of entire form object to watch child items in the object
                deep: true
            },
        },

        computed: {
            ...mapState([
                'user'
            ]),

            bookAppImage() {
                if (this.CurrentStep == 'firstStep' || this.CurrentStep == 'secondStep')
                    return '/images/img-2023/BookAppImages/Step-0_x1.jpg';
                else
                    return '/images/img-2023/BookAppImages/Step-2-London_x1.jpg';
            }
        },

        methods: {
            selectShowroom ({showroom}) {
                this.currentShowroom = showroom;
                this.setStep('thirdStep');

                this.selectedDate = null;
            },

            selectDate ({selectedInterest, selectedDate, preferedTime}) {
                this.selectedInterest = selectedInterest;
                this.selectedDate = selectedDate;
                this.preferedTime = preferedTime;

                this.setStep('fourthStep');
            },

            async selectUser ({userData}) {
                this.userData = userData;

                let captchaResponse = await captcha.getToken();

                await axios
                    .post(`/WebService.asmx/SaveRequest`, {
                        codePhone: userData.PhoneCode,
                        phone: userData.PhoneNumber,
                        email: userData.Email,
                        fullName: `${userData.FirstName} ${userData.LastName}`,
                        collectionPlace: this.currentShowroom.Id,
                        appointmentType: 2, //1 = online, 2 = resential
                        addinfo: '',
                        dateAppointment: '',
                        hourAppointment: '',
                        subject: '',
                        form: 'BookAppointment',
                        futureCall: false,
                        salePrice: '',
                        currencyId: '',
                        diamondCode: '',
                        uploadedFilename: '',
                        optedIn: true,
                        captchaResponse: captchaResponse,
                        enquiryType: 1,
                        virtualMeeting: false,
                        virtualMeetingLangId: 0,
                        virtualMeetingTechId: 0,
                        startDate: '',
                        endDate: '',
                        roomId: 0,
                        productTypeId: this.selectedInterest,
                        pickedProductTypes: [this.selectedInterest],
                        appointmentDate: this.selectedDate,
                        preferedTime: this.preferedTime,
                    })
                    .then((response) => {
                        if (response) {
                            this.showrooms = response.data.d;

                            this.setStep('lastStep');
                        }
                    });
                

            },

            // backToStep3() {
            //     this.setStep('thirdStep');
            //     this.loadCalendar(false, new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1, 0, 0, 0));
            // }
        },

        async mounted() {
            await axios
                .post(`/WebService.asmx/GetShowroomsByUserLocation`, {})
                .then((response) => {
                    if (response) {
                        this.showrooms = response.data.d;
                    }
                });
        },

        created() {
        },
    };
</script-->