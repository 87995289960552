// TODO: replace it with data from endpoint, look into

export const SHOWROOMS_MOCKED = [
  {
    id: 1,
    city: 'London',
    isOpen: true,
    address1: '3 Hanover Square',
    address2: '2nd floor',
    address3: 'London, W1S 1HD',
    phone: '+44 (0) 20 3540 1477',
    embedUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.9873006603693!2d-0.1450436845150879!3d51.513448979636074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876052ac28b76e1%3A0x7a2da6568ef107b3!2s77+Diamonds!5e0!3m2!1sen-EN!2spt!4v1522947033430!5m2!1sen-EN!2spt',
    directionUrl:
      'https://www.google.com/maps/dir/Current+Location/77+Diamonds,+3+Hanover+Square,+2nd+Floor,+London+W1S+1HD,+UK',
  },
  {
    id: 2,
    city: 'Manchester',
    isOpen: true,
    address1: 'Arkwright House',
    address2: 'Parsonage Gardens',
    address3: 'Manchester, M3 2LF',
    phone: '+44 (0) 20 3540 1477',
    embedUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d352.93008123813183!2d-2.249143671345913!3d53.482378790746466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb1c2d6a842a3%3A0x61bcfdf901e847cb!2s77+Diamonds!5e0!3m2!1sen-EN!2spt!4v1648808924393!5m2!1sen-EN!2spt',
    directionUrl:
      'https://www.google.com/maps/dir/Current+Location/77+Diamonds,+Arkwright+House,+Parsonage+Gardens,+Manchester+M3+2LF,+United+Kingdom,+UK',
  },
  {
    id: 3,
    city: 'Frankfurt',
    isOpen: false,
    address1: 'Taunustor 1',
    address2: '18th Floor',
    address3: 'Frankfurt am Main, 60311',
    phone: '+49 (0) 69 9675 9864',
    embedUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d707.7262607615147!2d8.672807224607304!3d50.11000387606224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd0f96a7e1bfa3%3A0xd2064eb2e9a81a19!2s77+Diamonds+GmbH!5e0!3m2!1sen-EN!2spt!4v1601633209606!5m2!1sen-EN!2spt',
    directionUrl:
      'https://www.google.com/maps/dir/Current+Location/77+Diamonds,Taunustor+1++Frankfurt+am+Main++60311++Frankfurt+Germany',
  },
  {
    id: 4,
    city: 'Berlin',
    isOpen: false,
    address1: 'Berlin',
    address2: 'Berlin',
    address3: 'Frankfurt am Main, 60311',
    phone: '+49 (0) 69 9675 9864',
    embedUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d707.7262607615147!2d8.672807224607304!3d50.11000387606224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd0f96a7e1bfa3%3A0xd2064eb2e9a81a19!2s77+Diamonds+GmbH!5e0!3m2!1sen-EN!2spt!4v1601633209606!5m2!1sen-EN!2spt',
    directionUrl:
      'https://www.google.com/maps/dir/Current+Location/77+Diamonds,Taunustor+1++Frankfurt+am+Main++60311++Frankfurt+Germany',
  },
]

export const USER_PREFERENCES_MOCKED = {
  userCountryId: 826, // Example: '826' for United Kingdom
  collectionPlaceId: 1, // Example collection place ID
  // userCountryId: 276, // Example: '276' for Germany
  // collectionPlaceId: 4, // Example collection place ID Berlin
}

export const COLLECTION_PLACES_MOCKED = {
  London: 1,
  Manchester: 2,
  Frankfurt: 3,
  Berlin: 4,
  Zurich: 5,
  Munich: 6,
  Stockholm: 7,
  Paris: 8,
}
