<template>
    <form novalidate @submit.prevent="">
        <button type="button" class="mb-3" @click="$emit('prevStep')">
            <i class="icon77 icon77-chevron -left"></i>
            <span class="heading-5 align-middle">
                {{$t('book-an-appointment', 'Book an Appointment')}}
            </span>
        </button>

        <p class="body-14-aa-cap my-3">
            {{$t('select-showroom', 'select a showroom')}}
        </p>

        <div class="locationSelector scrollable-container">
            <div class="scrollableContainer pr-3">
                <div v-for="showroom in Showrooms"
                    class="locationItem open border mb-3 p-3 px-4 hover:border-charcoal cursor-pointer"
                    @click="$emit('nextStep', {showroom})">
                    <h3 class="h4">
                        {{showroom.City}} Showroom
                    </h3>
                    <div class="details">
                        <div>
                            <p class="body-14-aa mb-0">
                                <template v-if="showroom.City != 'Dubai'">
                                    {{showroom.Address2}}<span class="responsive-comma">, </span>
                                    {{showroom.Address1}} 
                                    <br/>
                                    <template v-if="(showroom.CountryId == 276 || showroom.CountryId == 756 || showroom.CountryId == 752)">
                                        {{showroom.Address3.split(',').reverse().join(', ')}}
                                    </template>
                                    <template v-else>
                                        {{ showroom.Address3 }}
                                    </template>
                                    
                                </template>
                                <template v-else>
                                        {{showroom.Address2}}
                                        {{showroom.Address1}}
                                        <br/>
                                        {{showroom.Address3}}
                                </template>
                            </p>

                            <div v-if="!showroom.IsOpen">
                                <p class="body-12-aa float-right">
                                    * {{$t('pre-book-only','Pre-Booking Only')}}
                                </p>
                                <p class="h4">
                                    ({{$t('opening-soon','Opening soon')}})
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<style lang="scss">
</style>

<script setup>
import { useOnlineAppointments } from '../store'

let { Showrooms } = storeToRefs(useOnlineAppointments());
</script>

<!-- <script>
    import { mapState, mapActions, mapGetters} from 'vuex'
    import { required, minLength, between, email, requiredIf } from 'vuelidate/lib/validators'

    export default {
        props: ['showrooms'],

        data() {
            return {
            }
        },

        validations () {
            // let isDeliveryAddress = requiredIf(() => this.user.DeliveryOption == 'DeliveryAddress')
            
            return {
            };
        },

        computed: {
        },

        methods: {
            submit() {
                this.$emit('nextStep');
            }
        },
    }
</script> -->