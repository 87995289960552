<template>
  <section class="border-b">
    <div class="container mx-auto flex items-center md:p-[25px] lg:py-[22px] lg:px-8">
      <div class="w-1/4">
        <button type="button" class="my-2" @click="goBack">
          <i class="icon77 icon77-chevron -left"></i>
        </button>
      </div>
      <div class="localizedLogo mx-auto w-1/2 text-center">
        <HeaderLocalizeLogo />
      </div>
      <i class="icon77 icon77-secure ml-auto mr-1 -mt-1"></i>
      <h4 class="hidden lg:inline-block">
        {{ $t('secure-transaction', 'Secure Transaction') }}
      </h4>
    </div>
  </section>
</template>
<script setup lang="ts">
import HeaderLocalizeLogo from './HeaderLocalizeLogo.vue'

const router = useRouter()
const goBack = () => {
  if (window.history.length > 1) {
    router.back()
  } else {
    router.push('/')
  }
}
</script>

<style scoped lang="postcss"></style>
