<template>
  <Transition name="fade">
    <div v-if="isNativeCountryUS" id="currency-popup" key="us-popup">
      <p class="popup-text whitespace-normal">
        {{ $t('location-popup-text1', 'Would you like to change the location and currency to USA / USD?') }}
      </p>
      <span class="popup-button" @click="changeCountryToUSA()">{{ $t('yes', 'Yes') }}</span>
      <span class="popup-close-text popup-close" @click="close()">{{ $t('no-thanks', 'No, Thanks') }}</span>
    </div>

    <div
      v-else-if="!currentCountry?.DeliveryCountry"
      id="currency-popup"
      class="whitespace-normal nondispatch-country"
      key="no-dispatch-popup"
    >
      <button type="button" class="btn-link-variant popup-close-btn p-3" @click="close()">
        <i class="icon77 icon77-exit mr-2 -mt-1" />
      </button>
      <h2 class="h3 mb-3">Welcome to 77 Diamonds</h2>
      <p class="paragraph mb-1">
        We see you’re located in <b>{{ currentCountry?.Name }}</b
        >. Please note, there may be some difficulties in dispatching fine jewellery to this location.
      </p>
      <p class="paragraph mb-1">
        You can also collect items from one of our luxury showrooms. Please change your location, or find a showroom
        below.
      </p>
      <div class="flex justify-between w-full">
        <button type="button" class="change-location cta border-0" @click="changeLocation()">Change my location</button>
        <button type="button" class="change-showroom cta border-0" @click="changeShowroom()">Find a Showroom</button>
      </div>
    </div>
  </Transition>
</template>
<script setup lang="ts">
import { useNewSettingsStore } from '~/store/newSettingsStore'

import { USA_COUNTRY_ID } from '~/constants/common'

const emits = defineEmits(['close-popup', 'open-closest-showroom'])

const { changeCountryById } = useCurrencySelection()
const { eventBus, EVENTS } = useEventBus()
const { currentCountry } = storeToRefs(useNewSettingsStore())

const { width } = useWindowSize()

const isNativeCountryUS = computed(() => {
  return Number(currentCountry.value?.Id) === USA_COUNTRY_ID
}) // should be defined based on userPreference.UserCountry which is not clear if we should implement it

const close = () => {
  emits('close-popup')
}
const changeCountryToUSA = () => {
  changeCountryById(USA_COUNTRY_ID)
  close()
}

const changeLocation = async () => {
  const mobileBreakpoint = 1024 // copied from legacy

  if (width.value >= mobileBreakpoint) {
    eventBus.emit(EVENTS.OPEN_HEADER_CURRENCY_SELECTION)
  } else {
    eventBus.emit(EVENTS.OPEN_HEADER_NAVIGATION_MOBILE_MENU)

    await nextTick(() => {
      eventBus.emit(EVENTS.OPEN_HEADER_CURRENCY_SELECTION)
    })
  }
  close()
}

const changeShowroom = () => {
  emits('open-closest-showroom')
  close()
}
</script>

<style scoped lang="scss">
#currency-popup {
  position: absolute;
  top: 35px;
  left: 5px;
  z-index: 1000;
  text-align: center;
  min-width: 260px;
  border: 1px solid #e4e4e4;
  background-color: #ffffff;
  box-shadow: 0 4px 9px -5px #444444;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition:
    visibility 0.4s linear,
    opacity 0.4s linear;

  &.nondispatch-country {
    width: calc(100vw - 34px);

    @media only screen and (min-width: 768px) {
      width: 500px;
    }

    .popup-close-btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .popup-header {
    margin-top: 4px;
    color: #0d0900;
    font-family: 'Domaine Text Light';
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
  }

  .popup-text {
    color: #0d0900;
    width: 180px;
    font-family: 'Domaine Text Light';
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }

  .popup-icon {
    position: absolute;
    top: 0%;
    right: 0%;
    margin: 8px;
    cursor: pointer;
  }

  hr {
    width: 100%;
    margin: 16px 0;
  }

  .popup-button {
    cursor: pointer;
    color: #ffffff;
    font-family: 'Plain Light';
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.4px;
    line-height: 20px;
    text-align: center;
    background-color: #0d0900;
    border: 1px solid transparent;
    transition: all 0.4s linear;
    padding: 6px 33px;
    width: 100%;
    text-transform: uppercase;

    &:hover {
      color: #0d0900;
      border: 1px solid #0d0900;
      background-color: #ffffff;
    }
  }

  .popup-close-text {
    margin-top: 16px;
    color: #0d0900;
    font-family: 'Plain Light';
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
  }

  .paragraph {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
  }
}
</style>
