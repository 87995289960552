import { defineStore } from 'pinia'
import { fetchFromNewApi } from '~/helpers/api.ts'
import { useNewSettingsStore } from '~/store/newSettingsStore'

const { currentCountry, currentCurrency, currentLanguage } = storeToRefs(useNewSettingsStore())

const interests = [
  {
    id: 24,
    label: ['engagement-rings', 'Engagement Rings'],
  },
  {

    id: 45,
    label: ['wedding-eternity-rings', 'Wedding and Eternity Rings'],
  },
  {
    id: 48,
    label: ['other-jewellery', 'Other Jewellery'],
  },
  {
    id: 27,
    label: ['only-diams-gems', 'Only Diamonds and Gemstones']
  }
]


export const useOnlineAppointments = defineStore('onlineAppointments', {
  state: () => {
    return {
      CurrentStep: 'firstStep',
      // CurrentStep: 'lastStep',
      // CurrentStep: 'confirmation',
      Showrooms: null,
      CurrentShowroom: {},
      Interests: interests,
      SelectedInterest: null,
      DisabledDatesStatus: null,
      DisabledDates: null,
      SelectedDate: new Date(),
      PreferedTime: null,
      UserData: {
        FirstName: '',
        LastName: '',
        Email: '',
        PhoneCode: '',
        PhoneNumber: '',
      },
      AppointmentId: null,
      ErrorMessage: false
    }
  },

  actions: {
    async getShowrooms() {
      this.Showrooms = await fetchWithLoader('/api/v1/showrooms/closest');
    },

    setStep(step) {
      this.CurrentStep = step;
    },

    selectShowroom({showroom}) {
      this.CurrentShowroom = showroom;
      this.SelectedDate = null;

      this.CurrentStep = 'thirdStep';
    },

    async getDisabledDates(currentPage) {
      let currentDays = currentPage?.days;

      let firstDay = currentPage.monthComps.firstDayOfMonth,
        lastDay = currentPage.nextMonthComps.firstDayOfMonth;

      // debugger;

      this.DisabledDatesStatus = 'pending';
      
      const disabledDates = await fetchFromNewApi('/api/v1/booking/calendar-availability', {
        // method: 'POST',
        // immediate: false,
        params: {
          CollectionPlaceId: this.CurrentShowroom.Id,
          StartDay: firstDay?.toJSON(),
          EndDay: lastDay?.toJSON(),
          ProductTypeId: this.SelectedInterest,
        },
        // onRequest({ request, options }) {
        //   let currentDays = currentPage?.value?.days;
          
        //   options.params.StartDay = firstDay?.toJSON();
        //   options.params.EndDay = lastDay?.toJSON();
        //   options.params.ProductTypeId = this.SelectedInterest.value;
        // },
        // onResponse() {
        //   // calendar.value.scrollIntoView({behavior: 'smooth'});
        // },
        // transform: (value) => {
        //   debugger;
        //   return value.filter(d => !d.IsAvailable).map(d => new Date(d.Date.replace('+00:00', '')))
        // },
      })

      this.DisabledDates = disabledDates.filter(d => !d.IsAvailable).map(d => new Date(d.Date.replace('+00:00', '')));
      this.DisabledDatesStatus = '';
    },

    selectDate({preferedTime}) {
      this.PreferedTime = preferedTime;

      this.CurrentStep = 'fourthStep';
    },

    async selectUser ({userData}) {
      this.userData = userData;

      let captchaResponse = await useCaptcha().getToken();

      try {
        const appointment = await fetchWithLoader('/api/v1/booking/save-appointment-step1', {
          method: 'POST',
          body: {
            CodePhone: userData.PhoneCode,
            Phone: userData.PhoneNumber,
            Email: userData.Email,
            FullName: `${userData.FirstName} ${userData.LastName}`,
            CollectionPlace: this.CurrentShowroom.Id,
            OptedIn: true,
            CaptchaResponse: captchaResponse,
            AppointmentType: 2, //1 = online, 2 = presential
            EnquiryType: 1,
            VirtualMeeting: false,
            VirtualMeetingLangId: 0,
            VirtualMeetingTechId: 0,
            ProductTypeId: this.SelectedInterest,
            PickedProductTypes: [this.SelectedInterest],
            AppointmentDate: this.selectedDate,
            PreferedTime: this.PreferedTime,
            // Addinfo: '',
            // DateAppointment: '',
            // HourAppointment: '',
            // Subject: '',
            // Form: 'BookAppointment',
            // FutureCall: false,
            // SalePrice: '',
            // CurrencyId: '',
            // DiamondCode: '',
            // UploadedFilename: '',
            // StartDate: '',
            // EndDate: '',
            // RoomId: 0,
          }
        })

        this.AppointmentId = appointment.AppointmentId;

        this.setStep('lastStep');
      } catch (e) {
        this.ErrorMessage = e.data.message;
      }
    },

    async setAppointment({appointmentData}) {
      // this.userData = userData;

      try {
        const appointment = await fetchWithLoader('/api/v1/booking/save-appointment-step2', {
          method: 'POST',
          body: {
            appointmentId: this.AppointmentId,
            
            countryId: currentCountry.value?.Id,
            currencyId: currentCurrency.value?.Id,
            languageId: currentLanguage.value?.Id,
            
            needWhen: appointmentData.needIt.id,
            notes: appointmentData.notes,
            priceMax: appointmentData.price[0],
            priceMin: appointmentData.price[1],
            productTypeId: this.SelectedInterest,
            shapes: appointmentData.shapes.map(s => s.id),
            stoneType: appointmentData.stoneType.map(s => s.id),
            
            ringStyle: null,
            caratMax: 0,
            caratMin: 0,
            fancyColor: [],
            gemType: [],
          }
        })

        this.AppointmentId = appointment.AppointmentId;

        this.setStep('confirmation');
      } catch (e) {
        this.ErrorMessage = e.data.message;
      }
    },

    closeAlert() {
      this.ErrorMessage = false;
    }
  }
})