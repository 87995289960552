<template>
  <div
    :class="{ isHidden }"
    class="back_top_btn border !border-white border-solid h-12 w-12 [transition:opacity_0.5s_linear,visibility_0s] bg-white/50 rounded-full flex items-center justify-center cursor-pointer"
    @click="backToTop"
  >
    <span class="-translate-y-0.5">
      <i class="icon77 icon77-chevron"> </i>
    </span>
  </div>
</template>

<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core'

const { y } = useWindowScroll({ behavior: 'smooth' })
const isHidden = computed(() => y.value === 0)

const backToTop = () => {
  y.value = 0
}
</script>

<style scoped lang="scss">
.isHidden {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.5s linear,
    visibility 0s 0.5s;
}
</style>
