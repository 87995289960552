// import toast from '@components/ui/toast.js'
// import util from '@/util'

import { fetchFromProd, fetchFromNewApi } from '~/helpers/api.ts'
import { useNewSettingsStore } from '~/store/newSettingsStore'

const getSettingsStore = () => {
  return useNewSettingsStore()
}

export async function productsByConfig(configs) {
  try {
    // TODO: check if old params match new API params
    const res = await fetchFromNewApi('/api/v1/product/products-by-config', {
      method: 'POST',
      body: configs,
      preventLoader: true,
    })
    return res.Products
  } catch (e) {
    // throw error
    console.error(e)
  }
}

export async function categoryReviewsRequest(value) {
  try {
    // TODO: check if old params match new API params
    const res = await fetchFromNewApi('/api/v1/shop/reviews-by-category', {
      method: 'POST',
      body: value,
    })
    return res
  } catch (e) {
    // throw error
    console.error(e)
  }
}

export async function ConvertCurrencyWithSymbol(amount, roundup) {
  try {
    const { currentCurrency } = getSettingsStore()
    const res = await fetchFromNewApi(
      `/api/v1/accounting/amount-to-gbp?CurrencyId=${currentCurrency.Id}&Amount=${amount}&RoundUp=${roundup}`,
    )
    return res.d
  } catch (e) {
    // throw error
    console.error(e)
  }
}

export async function ConvertPoundsToUserCurrencyWithSymbol(amount, roundup = false) {
  try {
    const { currentCurrency } = getSettingsStore()
    const res = await fetchFromNewApi(
      `/api/v1/accounting/amount-from-gbp?CurrencyId=${currentCurrency.Id}&Amount=${amount}&RoundUp=${roundup}`,
    )
    return res.d
  } catch (e) {
    // throw error
    console.error(e)
  }
}

export async function ExchangeRate(amount, roundup = false) {
  try {
    const { currentCurrency } = getSettingsStore()
    const res = await fetchFromNewApi(
      `/api/v1/accounting/exchange-rate/gbp`,
      {
        params: {
          CurrencyId: currentCurrency.Id,
        }
      }
    )
    return res
  } catch (e) {
    // throw error
    console.error(e)
  }
}

export async function UploadImage(file) {
  const fileExtension = file.name.split('.').pop().toLowerCase()
  const allowedExtensions = ['jpeg', 'jpg', 'png', 'pdf']

  if (allowedExtensions.includes(fileExtension)) {
    if (file.size < 5024000) {
      const data = new FormData()
      data.append(file.name, file)

      try {
        // TODO: check with Vitor if we have new APi for this
        const res = await fetchFromProd('/Handlers/FileUploadHandler.ashx', {
          method: 'POST',
          body: data,
        })

        if (res) {
          return res
        } else {
          // toast.error(util.translate('please-try-again', 'Please Try again'))
        }
      } catch (e) {
        // throw error
        console.error(e)
      }
    } else {
      // toast.error(util.translate('file-cannot-exceed', 'The file size can not exceed 5MB.'))
    }
  } else {
    // toast.error(
    //   util.translate(
    //     'file-format-invalid',
    //     'Sorry, file format is invalid, allowed extensions are: .JPEG, .JPG, .PNG, .PDF only',
    //   ),
    // )
  }
}

export async function ContactForm(contact) {
  const uploadedFileName = contact.attachment ? await UploadImage(contact.attachment) : ''

  if (process.env.NODE_ENV != 'development') {
    try {
      const resp = await window.captcha?.getToken()
      const validCaptcha = !(resp == '' || resp == null)

      if (!validCaptcha) {
        throw Error()
      }
    } catch (error) {
      // toast.error(util.translate('verify-you-are-not-robot', 'You need to verify that you are not a robot'))
      return
    }
  }

  if (uploadedFileName == null) {
    return
  }

  const contactData = {
    codePhone: contact.phoneCode,
    phone: contact.phoneNumber,
    email: contact.email,
    fullName: contact.name,
    collectionPlace: null,
    appointmentType: -1,
    addinfo: contact.message,
    dateAppointment: '',
    hourAppointment: '',
    subject: '',
    form: 'ContactEmailUs',
    futureCall: false,
    salePrice: '',
    currencyId: getSettingsStore().currentCurrency.Id,
    diamondCode: '',
    uploadedFilename: uploadedFileName,
    optedIn: contact.marketingOption,
    captchaResponse: await window.captcha?.getToken(),
    enquiryType: '1',
    virtualMeeting: false,
    virtualMeetingLangId: 0,
    virtualMeetingTechId: 0,
    startDate: '',
    endDate: '',
    roomId: 0,
    productTypeId: 0,
    pickedProductTypes: [],
    appointmentDate: null,
  }

  try {
    // TODO: check with Eric and Vitor, looks like not in use anymore
    const res = await fetchFromProd('/WebService.asmx/SaveRequest', {
      method: 'POST',
      body: contactData,
    })

    if (res) {
      // toast.success(util.translate('successfully-sent', 'Successfully sent'))
      window.DataLayer?.submitForm('#contactForm[data-ga4-form]')
    } else {
      // toast.error(util.translate('please-try-again', 'Please Try again'))
    }
  } catch (e) {
    // throw error
    console.error(e)
  }
}

// use composable useCurrencySelection.ts instead
// export async function getCountries() {
//   try {
//     const res = await fetchFromProd('/WebService.asmx/GetCountriesForHeader', {
//       method: 'POST',
//     })
//
//     return JSON.parse(res.d)
//   } catch (e) {
//     // throw error
//     console.error(e)
//   }
// }

export async function getAddress(postcode) {
  try {
    const response = await fetchFromProd(
      `https://api.getAddress.io/v2/uk/${postcode}?api-key=yP6-zqoRkkKiu1HtBrbMGg6164`,
      {
        method: 'GET',
      },
    )
    return response
  } catch (e) {
    console.error(e)
    return false
  }
}

export async function royalMailSubscriptions(orderId, subscription) {
  try {
    const res = await fetchFromProd('/WebService.asmx/SetUpRoyalMailNotifications', {
      method: 'POST',
      body: {
        orderId,
        smsNotification: subscription === 'sms' || subscription === 'both',
        emailNotification: subscription === 'email' || subscription === 'both',
      },
    })

    return res.d
  } catch (e) {
    // throw error
    console.error(e)
  }
}
