<template>
  <footer class="border-t border-darkLighter-400" data-cy="footer">
    <div class="xl:container mt-6 md:mt-9">
      <div class="md:flex gap-3">
        <div class="md:basis-2/4 lg:basis-1/4 md:order-1">
          <FooterContactColumn />
        </div>
        <div id="sub-group-items" class="md:basis-2/4 lg:basis-3/4">
          <FooterNavigationSection :navigation-columns="footerNavigationColumns" />
        </div>
      </div>
    </div>
    <hr />
    <FooterBottomSection />
    <FooterCookieBar v-if="showCookieBar" @close="showCookieBar = false" />
    <div id="addToBagContainer"></div>
  </footer>
</template>

<script setup lang="ts">
import FooterNavigationSection from './FooterNavigationSection.vue'
import FooterContactColumn from './FooterContactColumn.vue'
import FooterBottomSection from './FooterBottomSection.vue'
import FooterCookieBar from './FooterCookieBar.vue'

import type { FooterNavigationColumn } from '~/components/layout/footer/types'

import { getAboutUsSection, getCustomerCare, getLegalAndPrivacySection } from '~/components/layout/footer/constants'

const { t } = useI18n()
const localePath = useLocalePath()
const { showCookieBar } = useLayout()
// TODO: Check if it's going to be hardcoded data or fetched from API
const footerNavigationColumns = computed(
  () =>
    [
      getCustomerCare(t, localePath),
      getAboutUsSection(t, localePath),
      getLegalAndPrivacySection(t, localePath),
    ] as FooterNavigationColumn[],
)
</script>
